import React, { useState } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';

const LLMSearch = () => {
  const [searchFields, setSearchFields] = useState({
    brand_name: '',
    generic_name: '',
    therapeutic_area: '',
    recommendation_type: '',
    project_status: '',
    date_submission_received: '',
    date_recommendation_issued: '',
    manufacturer_name: '',
    is_pcodr: false,
    is_rare_disease: false,
    project_number: '',
    recommendation_and_reasons_text: '',
  });
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [matchingRecords, setMatchingRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleFieldChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSearchFields(prevFields => ({
      ...prevFields,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setAnswer('');
    setMatchingRecords([]);
    try {
      const response = await axios.post('/api/llm-query', { searchFields, question });
      setAnswer(response.data.answer);
      setMatchingRecords(response.data.matchingRecords);
    } catch (error) {
      console.error('Error querying LLM:', error);
      setError('An error occurred while processing your query. Please try again.');
    }
    setLoading(false);
  };

  // Add this new function at the top of the component
  const LoadingSpinner = () => (
    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  );

  return (
    <div className="max-w-4xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Search Drug Reviews and Ask Questions</h2>
      <form onSubmit={handleSubmit} className="mb-6">
        <div className="grid grid-cols-2 gap-4 mb-4">
          {Object.entries(searchFields).map(([field, value]) => (
            <div key={field} className={field === 'recommendation_and_reasons_text' ? 'col-span-2' : ''}>
              <label htmlFor={field} className="block text-sm font-medium text-gray-700 mb-1">
                {field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
              </label>
              {field.includes('is_') ? (
                <input
                  id={field}
                  name={field}
                  type="checkbox"
                  checked={value}
                  onChange={handleFieldChange}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
              ) : field === 'recommendation_and_reasons_text' ? (
                <textarea
                  id={field}
                  name={field}
                  value={value}
                  onChange={handleFieldChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  rows="3"
                />
              ) : (
                <input
                  id={field}
                  name={field}
                  type={field.includes('date') ? 'date' : 'text'}
                  value={value}
                  onChange={handleFieldChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              )}
            </div>
          ))}
        </div>
        <div className="mb-4">
          <label htmlFor="question" className="block text-sm font-medium text-gray-700 mb-1">
            Your Question
          </label>
          <textarea
            id="question"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            placeholder="Enter your specific question"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows="3"
          />
        </div>
        <button
          type="submit"
          disabled={loading}
          className={`w-full px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${
            loading ? 'opacity-50 cursor-not-allowed' : ''
          } flex items-center justify-center`}
        >
          {loading ? (
            <>
              <LoadingSpinner />
              <span className="ml-2">Searching...</span>
            </>
          ) : (
            'Search and Ask'
          )}
        </button>
      </form>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {matchingRecords.length > 0 && (
        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-2 text-gray-800">Matching Records:</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-300">
              <thead>
                <tr>
                  {Object.keys(matchingRecords[0]).map((key) => (
                    <th key={key} className="py-2 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {key.replace(/_/g, ' ')}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {matchingRecords.map((record, index) => (
                  <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                    {Object.values(record).map((value, idx) => (
                      <td key={idx} className="py-2 px-4 border-b text-sm">
                        {typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {answer && (
        <div className="bg-gray-100 p-4 rounded-md">
          <h3 className="text-lg font-semibold mb-2 text-gray-800">Answer:</h3>
          <ReactMarkdown 
            className="text-gray-700 prose prose-sm max-w-none"
            components={{
              h3: ({node, ...props}) => <h3 className="text-lg font-semibold mt-4 mb-2 text-gray-800" {...props} />,
              p: ({node, ...props}) => <p className="mb-2" {...props} />,
              ul: ({node, ...props}) => <ul className="list-disc pl-5 mb-2" {...props} />,
              ol: ({node, ...props}) => <ol className="list-decimal pl-5 mb-2" {...props} />,
              li: ({node, ...props}) => <li className="mb-1" {...props} />,
            }}
          >
            {answer}
          </ReactMarkdown>
        </div>
      )}
    </div>
  );
};

export default LLMSearch;
